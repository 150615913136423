import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WhatsApp from "./Whatsapp.js";
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { Trans, useTranslation } from 'react-i18next';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

let setTimeoutId;

function useScrollIntoViewService() {
  useEffect((e) => {
    // so scrollInto view only runs once
    if (setTimeoutId) {
      clearTimeout(setTimeoutId)
    }

    setTimeoutId = setTimeout(() => {
      const id = window.location.hash;
      const element = id && document.getElementById(id.substring(1));
      
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
    
  }, [window.location.hash])
}



export default function Pricelist(props) {
  const { t } = useTranslation();

  // console.log(props.item.title);
  useScrollIntoViewService();
  const [showMore, setShowMore] = useState(false);
  const show = 'block';
  const hide = 'none';
  const details = props.item.treatments.map(({name, duration, price}) => {
    return ( 
      <Typography
                component="h1"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
                sx={{mt:4, textTransform: "uppercase" }}
    >
      <Grid container spacing={2}>
      <Grid item xs={10}>

        <Item>{name + ' '+ duration} </Item>
      </Grid>
      <Grid item xs={2}>
      
        <Item> {price}</Item>
        
      </Grid>
      
    </Grid> 
    <Divider light />
    </Typography> 
    
      )
  });

  return(  
        <Box sx={{
            width: '100%'
          }}>
              <Grid container rowSpacing={1} >
                <Grid item xs={12}  className="mirai-no-box-shadow ">
                    <Item lg={12} >
                      <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        sx={{mt:4, textTransform: "uppercase" }}
                        id={props.item.id}
                      >
                        {t(props.item.title)}
                      </Typography>
                        <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
                        {t(props.item.description)}
                        </Typography>
                        <Typography component="span" sx={{display: showMore ? show : hide}}>
                        <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
                        {t(props.item.description1)}
                        </Typography>
                        <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
                        {t(props.item.description2)}
                        </Typography>
                        <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
                        {t(props.item.description3)}
                        </Typography>
                      </Typography>
                      <Link   underline="always" variant="button"  onClick={() => setShowMore(!showMore)}>
                            {showMore ? t("show_less") : t("show_less")}
                        </Link>
                        
                    </Item>
                    {details}
                    
                </Grid>
           </Grid>
           <WhatsApp/>
        </Box>      
  );
}

 
