import Main from './Main'
import BooKNowButton from './BookNowBtn'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import InstagramIcon from '@mui/icons-material/Instagram'
import InstaFeed from './InstaFeed'
import { styled } from '@mui/material/styles'
import { Trans, useTranslation } from 'react-i18next';
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '100%',
  maxHeight: '100%',
});


export default function App() {
  const { t } = useTranslation();

  return (   
    <main>
       
        <Img src='../images/Salon/mirai-nails-amsterdam.webp' alt="Mirai Nails" />
        
        <Container maxWidth="lg">
        
            <Typography   color="text.secondary" sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}  >
              {t('home_text1')}
            </Typography>
            <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}  >
            {t('home_text2')}
            </Typography>
            <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
            {t('home_text3')}          
             </Typography>
            <Typography   color="text.secondary"  sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}   >
            {t('home_text4')}            
            </Typography>
            <Stack
              sx={{mb:4, pt:4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
             
            >  
            <BooKNowButton />
            <Button className="mirai-outline-button" href="https://www.instagram.com/mirai.nails/?r=nametag" variant="outlined">
              <Trans i18nKey="book_via_dm"></Trans> <InstagramIcon /> 
            </Button>
            </Stack>
            </Container>
            <Main />
            

            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{mt:6,pt:2, textTransform: "uppercase"}}

            >
            Instagram <InstagramIcon />  
            </Typography>
           
            <Typography
              
              variant="subtitle2"
              align="center"
              color="text.secondary"
              gutterBottom
              sx={{mt:1}}

            >
            
            <a href="https://www.instagram.com/mirai.nails/?r=nametag">
            @mirainails
              <InstaFeed token={'IGQWRQYnIybzIyUHJCV3FQRVRwRUk0WDJCODY0MW9EMWhyenBpT0tPMUNnWjlpUlVTajA2N2dTc0dreWxsZAVFObGNuVlpRS2hhMkVnSkNHTHdxYmJiT0t0eU1valJBclk5SXBtS2QyM3hZAemNxZAWlIMzNNWkwtR28ZD'} limit={16}/>
            </a>
            </Typography>

        
       
        
    </main>     
  );
}

