import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import OpeningTime from './components/OpeningTime'; 
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Trans, useTranslation } from 'react-i18next';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'justify',
  color: theme.palette.text.secondary,
}));

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  

const addressCard = (
    <React.Fragment>
      <CardContent >
        <Typography sx={{ fontSize: 14  }} color="text.secondary" gutterBottom>
              Mirai Nails
              Spuistraat 56
              Amsterdam       
        </Typography>
        <Typography variant="subtitle2" component="div">
          Call{bull}Us
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        06 43256620
        </Typography>
        <Typography variant="body2">
        <Typography variant="subtitle2" component="div">
          Email{bull}Us
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" text-decoration="underline">
        mirai.nails21@gmail.com
        </Typography>
         
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  


export default function BasicGrid() {
  const { t } = useTranslation();

  return (
      <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 2,
        pb: 6,
      }}
    >
    <Container maxWidth="lg" >
      <Box sx={{ flexGrow: 1, pt: 8 }}  >
          <Typography sx={{ textAlign:'center', pb:6}} variant="h4" component="div">
              Contact
          </Typography>
        <Grid container spacing={6} >
          <Grid item xs='auto' >
            <Item>
              <Typography sx={{fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
              {addressCard}
              </Typography>
                
            </Item>
          </Grid>
          <Grid item xs='auto' sx={{ textAlign:'justify'}} > 
            <Item>
              <Typography sx={{fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
                  {t('opening_hours')}
                  
                  {OpeningTime.map((item) => (
                     <Item item lg={12} md={8}  >
                     {bull} {t(item.title)} {item.from} - {item.to}
                     </Item>
                    
                ))}
              </Typography>
                
            </Item>
          </Grid>
        
          <Grid item >
            <Item>
              <Link href="https://www.google.nl/maps/place/Mirai.nails/@52.3760905,4.8900538,17z/data=!3m1!4b1!4m5!3m4!1s0x47c60985f0be1b9b:0x87b89d758a203ae5!8m2!3d52.3760905!4d4.8922425" underline="hover">
                <img
                    height="280"
                    src={"../images/misc/mirai-map-screenshot.png"}
                    alt="mirai nails map"
                    loading="lazy"
            />
              </Link>
              </Item>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>
  );
}
