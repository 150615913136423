import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import faqData from './components/faqData';
import { useTranslation } from 'react-i18next';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" sx={{mb:4 }}>
         <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{mt:4, textTransform: "uppercase" }}
              
            >
              FAQs
            </Typography>
            <Typography color="text.primary" sx={{mb:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
            {t("faq_intro")}
            </Typography>
            {faqData.map((item) => (
      <Accordion expanded={expanded === 'panel'+item.id} onChange={handleChange('panel'+item.id)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography color="text.primary" sx={{fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
            {t(item.title)}
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.secondary" sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
            {t(item.description)}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      
      ))}
      <Typography color="text.primary" sx={{mt:4, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
        {t("faq_end")}
    </Typography>
    </Container>
  );
}