import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useTranslation} from 'react-i18next';



export default function MainCard(props) {
  const { t } = useTranslation();

  return ( 
   <Container>
    
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        className='card-image'
        height="140"
        component="img"
        image={'../images/nailImages/'+props.item.coverImg}
        alt={t(props.item.title)}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {t(props.item.title)}
        </Typography>
        <Typography color="text.secondary" >
        {t(props.item.description)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" className="mirai-outline-button" href={"/services#"+props.item.id}>{t('pricelist_more')}</Button>
      </CardActions>
  </Card>
  </Container>
   
       
    
  );
}