import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageListItem from '@mui/material/ImageListItem';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const ImageGalleryList = styled('ul')(({ theme }) => ({
    display: 'grid',
    padding: 0,
    margin: theme.spacing(0, 4),
    gap: 8,
    [theme.breakpoints.up('xs')]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, 1fr)'
    },
}));


const InstaFeeds = ({token, ...props}) => {
    const [feeds, setFeedsData] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchInstagramPost () {
            try {
                const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${token}`);
                const feedData = response.data.data;
                const imageFeedData = feedData.filter(n => (n.media_type === "IMAGE" || n.media_type === "CAROUSEL_ALBUM"));
                const newFeeds = [...feeds, ...imageFeedData]; // Merge existing feeds with new feed data
                setFeedsData(newFeeds);
            } catch (err) {
                console.log('error', err)
            }
        }

        fetchInstagramPost();

        return () => {
            abortController.abort();
        };
    }, [props.limit]);

    return (
        <div className="container">
            <Feed feed={feeds} />
        </div>
    );
}


const Feed = (props) => {
    const feedpost = Array.isArray(props.feed) ? props.feed : [props.feed];
    
    let post = null;
    return(
       
            <ImageGalleryList>
                {feedpost.map((item) => (
                    <ImageListItem key={item.id} >
                         <img src={item.media_url} alt={item.caption} width="220" crop="fit"/>
                           
                    </ImageListItem>
                ))}
            </ImageGalleryList>
                
    ) 
   
  };
  

export default InstaFeeds;
