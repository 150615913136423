import * as React from 'react';

import MainCard from './MainCard';
import cardData from "./cardData";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Trans } from 'react-i18next';
import { styled } from '@mui/material/styles'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '100%',
  maxHeight: '100%'
  
});

export default function Main() {
    const cards = cardData.map(item => { return (
        <MainCard 
            key={item.id}
            item={item}
        />
        
    )})
 
 
  return (
       
          <Container>
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{mt:6, mb:4, pt:4, textTransform: "uppercase"}}
            >
            <Trans i18nKey="services"></Trans>
            </Typography>
            <Typography className="mirai-cards-list" >
              {cards}
            </Typography>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{mt:6, pt:6, textTransform: "uppercase"}}
            >
             <Trans i18nKey="getting_to_salon"></Trans>
            </Typography>
            <Typography  color="text.secondary" sx={{mt:4, pb:2, fontWeight: 300, fontSize:'calc((2.2 - 1) * 1.2vw + 0.5rem)'}}>
            <Trans i18nKey="getting_to_salon_text"></Trans>
            </Typography>
            <Link href="https://www.google.nl/maps/place/Mirai.nails/@52.3760905,4.8900538,17z/data=!3m1!4b1!4m5!3m4!1s0x47c60985f0be1b9b:0x87b89d758a203ae5!8m2!3d52.3760905!4d4.8922425" underline="hover">
                <Img
                    
                    src={"../images/misc/mirai-map.png"}
                    alt="mirai nails map"
                    loading="lazy"
            />
              </Link>
           

    
          </Container>
          
       
    
  );
}