import * as React from 'react';
import Container from '@mui/material/Container';
import Pricelist from './components/Pricelist';
import priceData from './components/priceData';
import Box from '@mui/material/Box';



export default function Services() {
    const price = priceData.map(item => { return (
        <Pricelist 
            key={item.id}
            item={item}
        />
        
    )})
    // console.log(price);
  return( 
    <Box
    sx={{
      bgcolor: 'background.paper',
      pt: 2,
      pb: 6,
    }}
  >
    <Container maxWidth="lg"> 
       {price} 
    </Container>    
    </Box>
  );
}

 
