export default [
    {
        id: 1,
        title: "mani_pedi",
        treatments:[
            {id:1,
                name: 'Manicure E-file without regular nail polish',
                duration: '30 min - 45 min',
                price: 'from €25,00'
            }, 
        ],
        coverImg: "minimal-nailart.jpg",
        openSpots: 0,
        description: 'service_mani_pedi1',
        description1:'service_mani_pedi2',
        description2:'service_mani_pedi3',
        description3:'service_mani_pedi4'
    },
    {
        id: 2,
        title: "gellak",
        treatments:[
            {id:1,
                name: 'Gel Polish - Manicure (e-file manicure)',
                duration: '1 hour 30 min',
                price: '€40,00'
            },
            {id:2,
                name: 'Gel Polish - Hands',
                duration: '30 min - 1 hour 15 min',
                price: 'from €25,00'
            }, 
            {id:3,
                name: 'Gel Polish - Feet (e-file manicure)',
                duration: '30 min - 50 min',
                price: 'from €25,00'
            },  
        ],
        coverImg: "minimal-nailart.jpg",
        openSpots: 0,
        description: 'service_gellak1',
        description2: 'service_gellak2',
        description3: 'service_gellak3',
        description3: 'service_gellak4'
    },
    {
        id: 3,
        title: "nail_art",
        treatments:[
            {id:1,
                name: 'Nail strengthening - BIAB ',
                duration: '1 hour 30 min - 2 hours',
                price: 'from €55,00'
            }, 
            {id:2,
                name: 'Gel Polish with extension - New set ',
                duration: '2 hours',
                price: 'from €50,00'
            },
            {id:3,
                name: 'Gel Polish with extension - Fillings ',
                duration: '1 hour 30 min - 2 hour',
                price: 'from €45,00'
            },
            {id:4,
                name: 'Nail Art ',
                duration: '30 min',
                price: 'from €1,00'
            },
            {id:5,
                name: 'Nail ornament ',
                duration: '10 min - 15 min',
                price: 'from €3,00'
            },
            {id:6,
                name: 'Gel Polish Removal incl. Manicure (no regular nail polish) ',
                duration: '50 min',
                price: 'from €40,00'
            },
            {id:7,
                name: 'Nail Reinforcement - BIAB Removable incl. Russian Manicure regular nail polish not included*',
                duration: '45 min',
                price: 'from €40,00'
            },
        ],
        coverImg: "minimal-nailart.jpg",
        openSpots: 0,
        description:'service_nailart1',
        description1:'service_nailart2',
        description2:'service_nailart3',
        description3:'service_nailart4'
    },
]