import React from "react";
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';

export default function BooKNowButton() {
    return(
        <Button className="mirai-outline-button" href="/book"  >
           <Trans i18nKey="book_now"></Trans>
        </Button>
    );
}