import React from "react";
import { Component } from "react";

export default class EmbedTreatwellWidget extends Component {
    render() {
        return (
            <div
            id="wahanda-online-booking-widget-iframe" style={{height:"calc(100vh - 200px)"}}
            data-widget-url="https://widget.treatwell.nl/en/place/416828/menu/"
            ></div>
        );
    }

    componentDidMount() {
        (function(){ 
                const script = document.createElement("script");
                script.src = "https://widget.treatwell.nl/common/venue-menu/javascript/widget-button.js?v1";
                script.async = true;
                document.body.appendChild(script);

                var link = document.createElement("link"); 
                link.type = "text/css"; 
                link.href = "https://widget.treatwell.nl/common/venue-menu/css/widget-button.css"; 
                link.rel = "stylesheet"; 
                link.media = "screen"; 
                document.getElementsByTagName("head")[0].appendChild(link); }
        ());  
    }
}