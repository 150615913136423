
export default [
    {
        id: 1,
        hash:"manicure-pedicure",
        title: "Manicure & Pedicure",
        description: "service_1_text",
        coverImg: "french-manicure.jpg",
        location: "Book Now",
        openSpots: 0,
    },
    {
        id: 2,
        hash:"gellak",
        title: "Gellak - Manicure & Pedicure",
        description: "service_2_text",
        coverImg: "autumn-nail.jpg",
        location: "Book Now",
        openSpots: 0,
    },
    {
        id: 3,
        hash:"nailart",
        title: "nail_art",
        description: "service_3_text",
        coverImg: "modern-art-nail.jpg",
        location: "Book Now",
        openSpots: 0,
    },
]