import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import BooKNowButton from './BookNowBtn'
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Language  from './Language.js';



const ITEM_HEIGHT = 58;


export default function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedItem, setSelectedItem] = useState('home');
  const { t } = useTranslation();

  const pages = [
  
    {
      id: 1,
      title: t('pricelist'),
      link:"services",
      className:"mirai-link"
    },
    
    {
      id: 2,
      title: "Gallery",
      link:"gallery",
      className:"mirai-link"
    },{
      id: 3,
      title: "FAQ",
      link:"faq",
      className:"mirai-link"
    }
  
    
  ]

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  function handleItemClick(item) {
    setSelectedItem(item);
    localStorage.setItem('selectedItem', item);
  }

  useEffect(() => {
    const item = localStorage.getItem('selectedItem');
    if (item) {
      setSelectedItem(item);
    }
  }, []);
  
    
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            onClick={() => handleItemClick( 'home')}
          >
            <img src='../mirai-logo.png' alt="Mirai Nails" className='logo-img'/>
          </Typography>
          <Typography component="a"   sx={{  minWidth: 120, display: 'none' }} className="nav-book-now">
                <BooKNowButton />
              </Typography>
          <Box sx={{ flexGrow: 0,  display: { xs: 'flex', md: 'none' } }} >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className="mirai-MuiSvgIcon-root"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', color:'#198d58', md: 'none' },
              }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '30ch',
                  ml:0
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu} divider="true" >
                  
          <Button underline="none" className={page.className} href={page.link} textAlign="center" sx={{width:'100%'}}>
                    {page.title}
                  </Button>
       
                  
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseNavMenu} divider="true" >
              <Button sx={{width:'100%'}}><BooKNowButton /></Button>
              </MenuItem>
            </Menu>
          </Box>
          
          <Box sx={{ flexGrow: 0,  display: { xs: 'none', md: 'flex'} }}  >
            {pages.map((page) => (
             
                <Button
                className={selectedItem === page.link  ? 'selected' : '' + ' mirai-link'}
                
                onClick={() => handleItemClick( page.link )}
                key={page.title}
                sx={{ my:2, color:'#c2a01e',minWidth: 120, display: 'block' }}
                href={"/"+page.link}
                align="center"
              >
                {page.title} 
                </Button>
                
              
            ))}
            
             
              <Button  sx={{ color:'#198d58',minWidth: 140, display: 'block' }} onClick={() => handleItemClick( 'book')}>
                <BooKNowButton />
              </Button>
             
          </Box>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
}
 