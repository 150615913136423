export default [
    {
        id: 1,
        title: "monday",
        from: "11:00",
        to: "19:00",
        open: 1,
    },
    {
        id: 2,
        title: "tuesday",
        from: "11:00",
        to: "19:00",
        open: 1,
    },
    {
        id: 3,
        title: "wednesday",
        from: "11:00",
        to: "19:00",
        open: 1,
    },
    {
        id: 4,
        title: "thursday",
        from: "11:00",
        to: "19:00",
        open: 1,
    },
    {
        id: 5,
        title: "friday",
        from: "11:00",
        to: "19:00",
        open: 1,
    },
    {
        id: 6,
        title: "saturday",
        from: "11:00",
        to: "20:00",
        open: 1,
    },
    {
        id: 7,
        title: "sunday",
        from: "12:00",
        to: "19:00",
        open: 1,
    },
]