import * as React from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '100%',
    
  });


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '1px solid #fff'
}

export default function Hero() {
    const arrayLength = itemData.length;
    const [open, setOpen] = React.useState(false);
    //const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedImageId, setSelectedImageId] = React.useState(0);
    const [selectedImage, setSelectedImage] = React.useState();
    const [selectedImagename, setSelectedImagename] = React.useState();
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    function handleOpen(id, img, name)
    {
         
        const index = itemData.findIndex((item) => item.id === id);
        setCurrentImageIndex(index);
        setSelectedImageId(id);
        setSelectedImage(img);
        setSelectedImagename(name);
        setOpen(true);
        
    }

    function goToPreviousImage() {
        const ind = currentImageIndex - 1;
        //alert(ind);
        setCurrentImageIndex(ind);
        //console.log(currentImageIndex);
    
        showImage(ind);
      }
      

    function goToNextImage()
    {
        const ind = currentImageIndex + 1;
        setCurrentImageIndex(ind);
        showImage(ind);
    }

    function showImage(index)
    {
        console.log('index: ' + index);
        console.log(arrayLength);
        if(index <= arrayLength && index>=0) {
            console.log('hello hello')
            const data = itemData[index];
            setCurrentImageIndex(index);
            setSelectedImageId(data.id);
            setSelectedImage(data.img);
            setSelectedImagename(data.title);
            setOpen(true);
        }
        else if (index > arrayLength)
        {
            //console.log('hello');
            index = 0;
            const data = itemData[index];
            console.log(data);
            setCurrentImageIndex(index);
            setSelectedImageId(data.id);
            setSelectedImage(data.img);
            setSelectedImagename(data.title);
            setOpen(true);

        }
        else if (index < 0)
        {
             console.log('less than 0');
            index = arrayLength;
            const data = itemData[index];
            console.log(data);
            setCurrentImageIndex(index);
            setSelectedImageId(data.id);
            setSelectedImage(data.img);
            setSelectedImagename(data.title);
            setOpen(true);
        }
    }

  return (
    <Box
        sx={{
        bgcolor: 'background.paper',
        pt: 6,
        }}
       
    >
     <Container maxWidth="lg" >
     <Masonry columns={3} spacing={3} sx={{margin:'0px'}} loading="lazy">
        {itemData.map((item, index) => (
          <div key={index}>
           <Button onClick={() => handleOpen(item.id, item.img, item.title)}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
                'pointer-events': 'all'
              }}
              
            />
            </Button>
          </div>
        ))}
      </Masonry>
     </Container>
     <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            <CloseOutlinedIcon/>
          </Button>
        </DialogActions>
        <DialogContent >
          <DialogContentText sx={{position:'relative'}}>
          <Typography id="transition-modal-description" >
                <Img  src={selectedImage} id={selectedImageId}/>
                
                   
            <ArrowBackIosSharpIcon onClick={() => goToPreviousImage()} sx={{position:'absolute',top:'50%',  color:'#bdbdbd'}}/>
            <ArrowForwardIosSharpIcon onClick={() => goToNextImage()} sx={{right:0,position:'absolute',top:'50%', color:'#bdbdbd'}}/>
            
                </Typography> 
                {selectedImagename}
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
    </Box>
  );
}

const itemData = [
    {
        id:'1',
        img: '../images/nailImages/egg-nails.webp',
        title: 'Manga nail Art',
    },
  
  {
    id:'2',
    img: '../images/nailImages/foodnailart.webp',
    title: 'Quirky Y2k mix nail art',
  },
  {
    id:'3',
    img: '../images/nailImages/tie-die-nail.webp',
    title: 'Tie die Nails',
  },
  {
    id:'4',
    img: '../images/nailImages/glitter-nails.webp',
    title: 'Glitter nails',
  },
  {
    id:'5',
    img: '../images/nailImages/swirl-nails.webp',
    title: 'Swirl Nails',
  },
  {
    id:'6',
    img: '../images/nailImages/manicure-with-nail-art.webp',
    title: 'Manicure with nail art',
  },
  {
    id:'7',
    img: '../images/nailImages/bear-nailart.webp',
    title: 'Bear nail art',
  },
  
  {
    id:'8',
    img: '../images/nailImages/jewelled-nails-1.webp',
    title: 'Jewelled Nails',
  },
  {
    id:'9',
    img: '../images/nailImages/dophamine-nails.webp',
    title: 'Dophamine Nails',
  },
  
  {
    id:'10',
    img: '../images/Salon/mirai-nails-6977609.webp',
    title: 'Mirai Nails salon',
  },
  {
    id:'11',
    img: '../images/Salon/mirai-nails-6977595.webp',
    title: 'Mirai Nails Salon Amsterdam',
  },
  {
    id:'12',
    img: '../images/Salon/mirai-nails-6977610.webp',
    title: 'Pedicure view at Mirai Nails Amsterdam',
  },
  

];
