import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function   Language() {
  
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const changeLanguage = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    localStorage.setItem('t', lng); // Store the selected language in localStorage
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} >
      <InputLabel id="demo-select-small">{t("language")}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        label="Language"
        value={i18n.language}
        onChange={changeLanguage}
      >
        <MenuItem key="en" value="en"> English </MenuItem>
        <MenuItem key="nl" value="nl"> Nederlands </MenuItem>    
      </Select>
    </FormControl>
  );
}


