import WhatsAppWidget from "./WhatsappWidget"

export default function WhatsApp() {
  
    return (   
      <main>
          <WhatsAppWidget
              chatTitle="Hello! Click one of our members below to chat on WhatsApp"
              chatPersonName={[{name:"Mirai", phone:"+31643256620", aka:"Sonu"} ]}
              headerIconColor="#2db742"
          />
      
      </main>     
    );
  }