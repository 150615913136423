import * as React from 'react';
import Container from '@mui/material/Container';
import EmbedTreatwellWidget from './components/EmbedTreatwellBookNow';

export default function BookingPage() {
    
  return( 
     
    <Container maxWidth="lg" style={{height:"100vh"}}> 
       <EmbedTreatwellWidget /> 
    </Container>    
  );
}