export default [
    {
        id: 1,
        title: "faq_title1",
        description: "faq_description1",
       
    },
    {
        id: 2,
        title: "faq_title2",
        description: "faq_description2",
    },
    {
        id: 3,
        title: "faq_title3",
        description: "faq_description3",
    },
    {
        id: 4,
        title: "faq_title4",
        description: "faq_description4",
    },
    {
        id: 5,
        title: "faq_title5",
        description: "faq_description5",
    },
    {
        id: 6,
        title: "faq_title6",
        description: "faq_description6",
    },
]